export function onDocumentEvent<K extends keyof DocumentEventMap>(event: K, eventHandler: (ev: DocumentEventMap[K]) => void) {
  onMounted(() => {
    document.addEventListener(event, eventHandler)
  })

  onUnmounted(() => {
    document.removeEventListener(event, eventHandler)
  })
}

export function onDocumentKeydown(key: string, eventHandler: (ev: KeyboardEvent) => void) {
  const keydownHandler = (ev: KeyboardEvent) => {
    if (ev.key === key) {
      eventHandler(ev)
    }
  }

  onDocumentEvent('keydown', keydownHandler)
}